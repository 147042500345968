import React from 'react'
// import style from './Containers.css';

interface Props {
  children?: any;
  maxWidth?: string;
  className?: string;
  bgImage?: string;
  href?: string;
  target?: string;
}

export const NavigationBar = ({ children, className }: Props) => {
  const styleClassName = className ? className : '';
  return <div className={`navigation-bar ${styleClassName}`}>{children}</div>
}

export const NavigationLink = ({ children, className, href, target }: Props) => {
  const styleClassName = className ? className : '';
  return <a href={href} target={target ? target : '_self'} className={`navigation-link ${styleClassName}`}>{children}</a>
}