import React from 'react'
// import style from './Footers.css';
import * as Icon from '../icons/Icons';

interface Props {
  children?: any;
}

export const Footer = ({ children }: Props) => {
  const year = new Date().getFullYear();
  return (
    <footer className='footer'>
      <div className='wrapper-left'>
        <div className='logo-footer'></div>
        <div>
          <div>© {year} Improovia</div>
          <div><a href="https://" target="_blank">Política de privacidad</a> | <a href="https://" target="_blank">Términos y condiciones</a></div>
        </div>
      </div>
      <div>{children}</div>
      <div className='wrapper-right'>
        <div className="social-nav-wrapper">
          {/*<div className="item"><a href="https://www.facebook.com/" target="_blank"><Icon.IconFacebook /></a></div>*/}
          <div className="item"><a href="https://www.linkedin.com/company/improovia/" target="_blank"><Icon.IconLinkedin /></a></div>
          {/*
          <div className="item"><a href="https://www.instagram.com/" target="_blank"><Icon.IconInstagram /></a></div>
          <div className="item"><a href="https://www.youtube.com/" target="_blank"><Icon.IconYoutube /></a></div>
          <div className="item"><a href="https://vm.tiktok.com/" target="_blank"><Icon.IconTikTok /></a></div>*/}
        </div>
      </div>
    </footer>
  )
}