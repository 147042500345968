import { useState, useEffect } from 'react';

export function slugify(str: string) {
  str = str.replace(/^\s+|\s+$/g, '');
  // Make the string lowercase
  str = str.toLowerCase();
  // Remove accents, swap ñ for n, etc
  var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
  var to   = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
  for (var i=0, l=from.length ; i<l ; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }
  // Remove invalid chars
  str = str.replace(/[^a-z0-9 -]/g, '')
  // Collapse whitespace and replace by -
  .replace(/\s+/g, '-')
  // Collapse dashes
  .replace(/-+/g, '-');
  return str;
}

export function amountPriceDecimal(price: number) {
  const amountCent = String(price);
  const convertCentToPrice = amountCent.substring(0, amountCent.length-2);
  const convertCentToPriceDecimal = amountCent.substring(amountCent.length-2,amountCent.length);
  const price_format = convertCentToPrice+'.'+convertCentToPriceDecimal;
  
  return Number.parseFloat(price_format).toFixed(2);
}

const getWindowDimensions = () => {
  const { innerWidth: width } = window;
  let device = 'desktop';

  if (width < 768) {
    device = 'mobile';
  } else {
    device = 'desktop';
  }
  return {
    device
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

type dateFormatProp = {
  dateToMonth?: any;
  timestampToDate?: any;
  dateToTimestamp?: any;
  date?: any;
};

export const dateFormat: dateFormatProp = {
  dateToMonth: (time: any) => {
    const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];
    const date = new Date(time);
    return monthNames[date.getMonth()];    
  },
  timestampToDate: (timestamp:any) => {
    const date = new Date(timestamp * 1000);
    const year = date.getUTCFullYear();
    const month = date.getMonth()>9 ? date.getMonth() : "0"+date.getMonth();
    const day = date.getDay()>9 ? date.getDay() : "0"+ date.getDay();
    const hours = date.getHours()>9 ? date.getHours() : "0"+date.getHours();
    const minutes = date.getMinutes()>9 ? date.getMinutes() : "0"+date.getMinutes();
    const formattedTime = day+'/'+month+'/'+year+' '+ hours + ':' + minutes+'hs';
    return formattedTime;
  },
  dateToTimestamp: (time_date:any) => {
    console.log('time_date.length: ',time_date.length);
    const time_date_integer = parseInt(time_date);
    const time_date_converted =new Date(time_date_integer);
    if(time_date.length>10)
      return Math.floor(time_date_converted.getTime()/1000);
    return time_date_integer;
  },
  date: (time_date:any) => {
    const time_date_year=time_date.getFullYear();
    const time_date_month=(time_date.getMonth() + 1).toString().padStart(2, "0");
    const time_date_day= time_date.getDate().toString().padStart(2, "0");
    return ({
      'year': time_date_year,
      'month': time_date_month,
      'day': time_date_day
    })
  }
}

type meetFormatProp = {
  interval?: any;
}

export const meetFormat: meetFormatProp = {
  interval: (time_start: any, time_end: any) => {
    if(!time_start&&!time_end) {
      return 'Undefined';
    }
    const time_start_converted= time_start.toString();
    const date_converter_start = new Date(time_start_converted*1000);
    const dateYear = dateFormat.date((date_converter_start)).year;
    const dateMonth = dateFormat.date(date_converter_start).month;
    const dateDay = dateFormat.date(date_converter_start).day;
    
    const hour_start = date_converter_start.getHours()>9 ? date_converter_start.getHours() : "0"+date_converter_start.getHours();
    const minutes_start = date_converter_start.getMinutes()>9 ? date_converter_start.getMinutes() : "0"+date_converter_start.getMinutes();
    const result_time_start = hour_start+':'+minutes_start;
  
    const time_end_converted= time_end.toString();
    const date_converter_end = new Date(time_end_converted*1000);
    const hour_end = date_converter_end.getHours()>9 ? date_converter_end.getHours() : "0"+date_converter_end.getHours();
    const minutes_end = date_converter_end.getMinutes()>9 ? date_converter_end.getMinutes() : "0"+date_converter_end.getMinutes();
    const result_time_end = hour_end+':'+minutes_end;

    const date = `${dateDay}/${dateMonth}/${dateYear}`;

    return `${date} ${result_time_start}hs a ${result_time_end}hs`; 
  }
}

type recordStatusProp = {
  recordStatusToText?: any;
  list?: any;
};

export const recordStatus: recordStatusProp = {
  recordStatusToText: (record_status:string) => {
    switch (record_status) {
      case 'in_process':
        return 'En proceso';
        break;
      case 'attended':
        return 'Atendido';
        break;
      case 'absent':
        return 'Ausente';
        break;
      case 'not_attended':
        return 'No atendido';
        break;
      default:
        return 'none';
        break;
    }
  },
  list: () => {
    const record_status_list = [
      {
        'label': 'En proceso',
        'value': 'in_process'
      },
      {
        'label': 'Atendido',
        'value': 'attended'
      },
      {
        'label': 'Ausente',
        'value': 'absent'
      },
      {
        'label': 'No atendido',
        'value': 'not_attended'
      }
    ]
    return record_status_list;
  }

}

export const professionTypeToName = (profession_type: string) => {
  switch (profession_type) {
    case 'fisioterapia':
      return 'Fisioterapia'
    case 'nutricion' :
      return 'Nutrición'
    case 'coaching' :
      return 'Coaching'
    default:
      return 'Indefinido'
  }
}

const textToRecordStatus = (record_status: string) => {
  return ''
}

const recordStatusList = () => {
  return ''
}
