import * as React from "react"

function IconMenu(props: any) {
  const size = (props.size) ? props.size : 'normal';

  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`icon ${size}`}
    >
      <path
        d="M2.847 6.753c0-.942.766-1.707 1.707-1.707h22.892c.941 0 1.706.765 1.706 1.707 0 .941-.765 1.707-1.707 1.707H4.555a1.71 1.71 0 0 1-1.708-1.707Zm24.598 7.54H4.555A1.71 1.71 0 0 0 2.846 16c0 .941.766 1.707 1.707 1.707h22.892c.94 0 1.706-.766 1.706-1.707 0-.941-.765-1.707-1.707-1.707Zm0 9.247H4.555c-.942 0-1.708.766-1.708 1.707a1.71 1.71 0 0 0 1.707 1.707h22.892c.94 0 1.706-.766 1.706-1.707 0-.941-.765-1.707-1.707-1.707Z"
        fill="#000"
      />
    </svg>
  )
}

export default IconMenu
