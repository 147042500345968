import * as React from "react"

function IconHome(props: any) {
  const size = (props.size) ? props.size : 'normal';

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`icon ${size}`}
    >
      <g clipPath="url(#a)">
      <path
        d="m31.2 13.856-.002-.002L18.144.8a2.926 2.926 0 0 0-2.083-.864c-.787 0-1.527.307-2.083.864L.93 13.847a2.95 2.95 0 0 0-.008 4.173 2.929 2.929 0 0 0 2.046.864h.52v9.606a3.452 3.452 0 0 0 3.448 3.448h5.107c.518 0 .938-.42.938-.938V23.47c0-.868.706-1.573 1.573-1.573h3.012c.868 0 1.573.705 1.573 1.573V31c0 .518.42.938.938.938h5.107a3.452 3.452 0 0 0 3.448-3.448v-9.606h.482c.787 0 1.527-.307 2.083-.864a2.95 2.95 0 0 0 .002-4.164Zm-1.328 2.839a1.064 1.064 0 0 1-.757.314h-1.42a.937.937 0 0 0-.937.937V28.49c0 .867-.706 1.573-1.573 1.573h-4.17v-6.594a3.452 3.452 0 0 0-3.448-3.448h-3.012a3.452 3.452 0 0 0-3.448 3.448v6.594h-4.17a1.575 1.575 0 0 1-1.573-1.573V17.946a.937.937 0 0 0-.937-.937H3.03a1.063 1.063 0 0 1-.781-.314 1.072 1.072 0 0 1 0-1.515l.001-.001L15.304 2.126c.202-.202.471-.313.757-.313.286 0 .555.11.757.313l13.05 13.05.006.006a1.073 1.073 0 0 1-.002 1.513Z"
        fill="#4E73BC"
      />
      </g>
      <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
      </defs>
    </svg>
  )
}

export default IconHome
