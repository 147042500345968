import * as React from "react"

function IconFooterLogo(props: any) {
  const size = (props.size) ? props.size : 'normal';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={503}
      height={70}
      fill="none"
      {...props}
    >
      <path
        fill="#3B82F6"
        d="M268.656 69.024c-6.336 0-12.16-1.472-17.472-4.416-5.248-2.944-9.44-7.04-12.576-12.288C235.536 47.008 234 41.056 234 34.464c0-6.592 1.536-12.512 4.608-17.76 3.136-5.248 7.328-9.344 12.576-12.288C256.496 1.472 262.32 0 268.656 0c6.336 0 12.128 1.472 17.376 4.416 5.312 2.944 9.472 7.04 12.48 12.288 3.072 5.248 4.608 11.168 4.608 17.76 0 6.592-1.536 12.544-4.608 17.856-3.072 5.248-7.232 9.344-12.48 12.288-5.248 2.944-11.04 4.416-17.376 4.416Zm0-14.976c5.376 0 9.664-1.792 12.864-5.376 3.264-3.584 4.896-8.32 4.896-14.208 0-5.952-1.632-10.688-4.896-14.208-3.2-3.584-7.488-5.376-12.864-5.376-5.44 0-9.792 1.76-13.056 5.28-3.2 3.52-4.8 8.288-4.8 14.304 0 5.952 1.6 10.72 4.8 14.304 3.264 3.52 7.616 5.28 13.056 5.28Z"
      />
      <path
        fill="#3B82F6"
        d="M298.656 69.024c-6.336 0-12.16-1.472-17.472-4.416-5.248-2.944-9.44-7.04-12.576-12.288C265.536 47.008 264 41.056 264 34.464c0-6.592 1.536-12.512 4.608-17.76 3.136-5.248 7.328-9.344 12.576-12.288C286.496 1.472 292.32 0 298.656 0c6.336 0 12.128 1.472 17.376 4.416 5.312 2.944 9.472 7.04 12.48 12.288 3.072 5.248 4.608 11.168 4.608 17.76 0 6.592-1.536 12.544-4.608 17.856-3.072 5.248-7.232 9.344-12.48 12.288-5.248 2.944-11.04 4.416-17.376 4.416Zm0-14.976c5.376 0 9.664-1.792 12.864-5.376 3.264-3.584 4.896-8.32 4.896-14.208 0-5.952-1.632-10.688-4.896-14.208-3.2-3.584-7.488-5.376-12.864-5.376-5.44 0-9.792 1.76-13.056 5.28-3.2 3.52-4.8 8.288-4.8 14.304 0 5.952 1.6 10.72 4.8 14.304 3.264 3.52 7.616 5.28 13.056 5.28Z"
      />
      <path
        fill="#000"
        d="M16.416 0v67.392H0V0h16.416Zm88.217 0v67.392H88.217V26.976L73.143 67.392H59.896L44.729 26.88v40.512H28.312V0h19.393l18.912 46.656L85.337 0h19.296Zm63.165 21.696c0 3.904-.896 7.488-2.688 10.752-1.792 3.2-4.544 5.792-8.256 7.776-3.712 1.984-8.32 2.976-13.824 2.976h-10.176v24.192h-16.416V0h26.592c5.376 0 9.92.928 13.632 2.784 3.712 1.856 6.496 4.416 8.352 7.68 1.856 3.264 2.784 7.008 2.784 11.232Zm-26.016 8.448c3.136 0 5.472-.736 7.008-2.208 1.536-1.472 2.304-3.552 2.304-6.24s-.768-4.768-2.304-6.24c-1.536-1.472-3.872-2.208-7.008-2.208h-8.928v16.896h8.928Zm68.93 37.248-14.016-25.44h-3.936v25.44h-16.416V0h27.552c5.312 0 9.824.928 13.536 2.784 3.776 1.856 6.592 4.416 8.448 7.68 1.856 3.2 2.784 6.784 2.784 10.752 0 4.48-1.28 8.48-3.84 12-2.496 3.52-6.208 6.016-11.136 7.488l15.552 26.688h-18.528ZM192.76 30.336h10.176c3.008 0 5.248-.736 6.72-2.208 1.536-1.472 2.304-3.552 2.304-6.24 0-2.56-.768-4.576-2.304-6.048-1.472-1.472-3.712-2.208-6.72-2.208H192.76v16.704ZM403.952 0l-23.904 67.392h-20.544L335.6 0h17.472l16.704 50.88L386.576 0h17.376Zm23.277 0v67.392h-16.416V0h16.416Zm53.848 55.488h-25.152l-4.032 11.904h-17.184L459.093 0h19.008l24.384 67.392h-17.376l-4.032-11.904Zm-4.224-12.672-8.352-24.672-8.256 24.672h16.608Z"
      />
    </svg>
  )
}

export default IconFooterLogo