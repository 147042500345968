import React from 'react'

interface Props {
  visible: Boolean;
  className?: string;
}

export const Loading = ({ visible, className }: Props) => {
  const styleClassName = className ? className : '';
  if(visible) {
    return <div className={`loading-animated ${styleClassName}`}></div>
  }
  return null;
}