import React from 'react'
import '../../css/UploadFile.css'

interface Props {
  label: string;
  className?: string;
  icon?: any;
  action?: any;
  loading?: Boolean;
  disabled?: Boolean;
}

export const UploadFile = ({ label, className, icon, action, loading, disabled }: Props) => {
  const btnIcon = (icon) ? icon : null;
  const disabled_btn = (disabled) ? 'disabled' : null;
  if(loading) return <button className={`btn ${className}`} ><div className='loading-dots-animated space'></div> Uploading</button>
  return <button className={`btn ${className} ${disabled_btn}`} onClick={()=>action()}>{btnIcon}{label}</button>
}
