import React from 'react'

interface Props {
  children?: any;
  maxWidth?: string;
  className?: string;
  bgImage?: string;
}

export const Environment = ({ children }: Props) => {
  return (<div className='environment-wrapper'>{children}</div>);
}
