import * as React from "react"

function IconInstagram(props: any) {
  const size = (props.size) ? props.size : 'normal';

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`icon ${size}`}
    >
      <g clip-path="url(#clip0_104_16)">
      <path d="M19.399 9.09509H12.6079C10.6736 9.09509 9.09497 10.6666 9.09497 12.608V19.3991C9.09497 21.3333 10.6665 22.912 12.6079 22.912H19.399C21.3332 22.912 22.9119 21.3404 22.9119 19.3991V12.608C22.9047 10.6666 21.3332 9.09509 19.399 9.09509ZM15.9999 20.544C13.4967 20.544 11.4559 18.5031 11.4559 16C11.4559 13.4969 13.4967 11.456 15.9999 11.456C18.503 11.456 20.5439 13.4969 20.5439 16C20.5439 18.5031 18.503 20.544 15.9999 20.544V20.544ZM20.7714 11.9609C20.3447 11.9609 20.0034 11.6195 20.0034 11.1929C20.0034 10.7662 20.3447 10.4249 20.7714 10.4249C21.1981 10.4249 21.5394 10.7662 21.5394 11.1929C21.5394 11.6195 21.1981 11.9609 20.7714 11.9609Z" fill="#607D8B"/>
      <path d="M18.7623 16.0336C18.7806 14.5098 17.5601 13.2597 16.0363 13.2414C14.5125 13.2231 13.2623 14.4436 13.244 15.9674C13.2258 17.4912 14.4462 18.7413 15.97 18.7596C17.4939 18.7779 18.744 17.5574 18.7623 16.0336Z" fill="#607D8B"/>
      <path d="M16 0C7.16089 0 0 7.16089 0 16C0 24.8391 7.16089 32 16 32C24.8391 32 32 24.8391 32 16C32 7.16089 24.8391 0 16 0ZM24.8889 19.3991C24.8889 22.4284 22.4284 24.8889 19.3991 24.8889H12.608C9.57867 24.8889 7.11822 22.4284 7.11822 19.3991V12.608C7.11822 9.57867 9.57867 7.11822 12.608 7.11822H19.3991C22.4284 7.11822 24.8889 9.57867 24.8889 12.608V19.3991Z" fill="#607D8B"/>
      </g>
      <defs>
      <clipPath id="clip0_104_16">
      <rect width="32" height="32" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}

export default IconInstagram
