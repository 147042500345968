import * as React from "react"

function IconInfo(props: any) {
  const size = (props.size) ? props.size : 'normal';

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`icon ${size}`}
    >
      <path
        d="M31.992 15.478C31.724 7.072 24.928.277 16.522.008 11.948-.137 7.67 1.604 4.507 4.87 1.467 8.008-.131 12.147.008 16.522c.269 8.406 7.064 15.202 15.47 15.47a15.872 15.872 0 0012.016-4.861c3.04-3.14 4.638-7.278 4.498-11.653zm-6.919 9.308a12.529 12.529 0 01-9.488 3.838c-6.634-.212-11.997-5.575-12.209-12.209a12.54 12.54 0 013.55-9.201 12.53 12.53 0 019.489-3.838c6.634.212 11.997 5.575 12.21 12.21a12.541 12.541 0 01-3.552 9.2z"
        fill="#fff"
      />
      <path
        d="M16.06 14a2 2 0 012 2v6a2 2 0 01-4 0v-6a2 2 0 012-2zM15.882 8.001a2 2 0 100 4 2 2 0 000-4z"
        fill="#fff"
      />
    </svg>
  )
}

export default IconInfo
