import * as React from "react"

function IconUser(props: any) {
  const size = (props.size) ? props.size : 'normal';

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`icon ${size}`}
    >
      <path
        d="M14 2.333C7.56 2.333 2.333 7.56 2.333 14c0 6.44 5.227 11.667 11.667 11.667 6.44 0 11.667-5.227 11.667-11.667C25.667 7.56 20.44 2.333 14 2.333zm0 3.5c1.937 0 3.5 1.564 3.5 3.5 0 1.937-1.563 3.5-3.5 3.5a3.495 3.495 0 01-3.5-3.5c0-1.936 1.563-3.5 3.5-3.5zM14 22.4a8.4 8.4 0 01-7-3.757c.035-2.321 4.667-3.593 7-3.593 2.322 0 6.965 1.272 7 3.593a8.4 8.4 0 01-7 3.757z"
        fill="#4E73BC"
      />
    </svg>
  )
}

export default IconUser