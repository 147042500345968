import React from 'react';

export const H1 = (props: any) => {
  const { children, className } = props;

  return (
    <h1 className={className}>
      {children}
    </h1>
  );
};

export const H2 = (props: any) => {
  const { children, className } = props;

  return (
    <h2 className={className}>
      {children}
    </h2>
  );
};

export const H3 = (props: any) => {
  const { children, className } = props;

  return (
    <h3 className={className}>
      {children}
    </h3>
  );
};

export const H4 = (props: any) => {
  const { children, className } = props;

  return (
    <h4 className={className}>
      {children}
    </h4>
  );
};

export const H5 = (props: any) => {
  const { children, className } = props;

  return (
    <h5 className={className}>
      {children}
    </h5>
  );
};

export const H6 = (props: any) => {
  const { children, className } = props;

  return (
    <h6 className={className}>
      {children}
    </h6>
  );
};

export const P = (props: any) => {
  const { children } = props;

  return (
    <p>
      {children}
    </p>
  );
};