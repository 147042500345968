import * as React from "react"

function IconFilter(props: any) {
  const size = (props.size) ? props.size : 'normal';

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`icon ${size}`}
    >
      <path
        d="M4.958 6.545c2.357 3.022 6.709 8.622 6.709 8.622v7a1.17 1.17 0 001.166 1.166h2.334a1.17 1.17 0 001.166-1.166v-7s4.34-5.6 6.697-8.622a1.164 1.164 0 00-.922-1.878H5.88a1.164 1.164 0 00-.922 1.878z"
        fill="#FFFFFF"
      />
    </svg>
  )
}

export default IconFilter
