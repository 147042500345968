    import React, { useState } from 'react'
    // import style from './src/tables.css';

  interface Props {
    children?: any;
    label?: any;
    maxWidth?: string;
    className?: string;
  }

  /*export const Tab = ({ children, className }: Props) => {
    const styleClassName = className ? className : '';
    return <div className={`tab ${styleClassName}`}>{children}</div>
  }
*/
  export const Tabs = ({ children, className }: Props) => {
    const styleClassName = className ? className : '';
    const initShowTab = (children[0].props.data) ? children[0].props.data : '';
    const [showTab, setShowTab] = useState(initShowTab);

    const openTab = (tabName: any) => {
      setShowTab(tabName);
    }

    return (
      <div className={`tabs-wrapper ${styleClassName}`}>
        <div className="tab">
          {children.map((child: any, index: any) => {
            const tabName = child.props.data;
            return (<button key={index} className={`tablinks ${showTab===tabName ? 'active' : '' }`} onClick={() => openTab(tabName)}>{tabName}</button>);
          })}
        </div>
        {children.map((child: any, index: any) => {
          const tabName = child.props.data;
          return (
            <div key={index} className={`tabcontent ${showTab===tabName ? 'show' : '' }`}>
              {child}
            </div>
          )
        })}
      </div>
    )
  };

  export const Tab = ({ children, data }: any) => {
    return <div>{children}</div>
  };