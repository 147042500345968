import React, { useState } from 'react'
// import styles from './Headers.css'
import * as Icon from '../icons/Icons';
import { Input } from '../inputs/Inputs';
import * as JS from '../../js/JS';
import { useDetectClickOutside } from 'react-detect-click-outside';

interface Props {
  sessionLinks: {
    logo: {
      label: string,
      url: string,
      class: string
    },
    public: any,
    private: any
  };
  children?: any;
}

const Dropdown = ({ closeToggle, children }:any) => {
  const ref = useDetectClickOutside({ onTriggered: closeToggle });
  return (
    <div className="dropdown" ref={ref}>
      {children}
    </div>
  );
};

export const Header = ({children, sessionLinks}: Props) => {
  const { device } = JS.useWindowDimensions();
  const session = JS.getSession();
  // const url_page =`<iframe id="auth" src='http://account.example.com/page403'/>`; // sandbox="allow-same-origin"
  const style = {
    display: "flex",
  };

  const [toggleDropdown, setToggleDropdown] = useState(false);
  const handleToggle = (e:any) => {
    e.preventDefault();
    setToggleDropdown((prevState) => !prevState);
  };
  const closeToggle = () => {
    setToggleDropdown(false);
  };
  
  /*const sessionLinks = {
    logo: {
      'label': 'Logo',
      'url': './jjjjjjj',
      'class': ''
    },
    public: [
      {
        'label': 'Inicia sesión',
        'url': 'https://account.example.com',
        'class': 'btn-session'
      },
      {
        'label': 'Crear cuenta',
        'url': 'https://account.example.com/signUp',
        'class': 'btn-session btn-sign-up'
      }
    ],
    private: [
      {
        'label': 'Mi perfil',
        'url': 'https://account.example.com/profile',
        'class': ''
      },
      {
        'label': 'Salir',
        'url': 'https://account.example.com/close',
        'class': ''
      }   
    ]
  }*/

  if(device==='mobile') {
    return (<header className='header-mobile' style={style}>
      <div className="wrapper-content">
        <div><a href={(session.account.account_id!==null) ? sessionLinks.logo.url : './'}><div className='logo'></div></a></div>
      </div>
      <div className="wrapper-session">
        <button className="btn-transparent btn-user" onClick={handleToggle}>
          <Icon.IconMenu />
        </button>
      </div>
      <div className="mobile-menu  ">
      {toggleDropdown && <Dropdown closeToggle={closeToggle}>
        {(session.account.account_id!==null) ? (
          <div className="wrapper-nav ">
            {children}
            <ul>
              {sessionLinks.private.map((item:any, index:any)=>{
                return (<li><a key={index} href={item.url} className={`${item.class}`}>{item.label}</a></li>)
              })}
            </ul>
          </div>
        ) : (          
          <div className="wrapper-nav">
            {children}
            <ul className='align-btn-session'>
              {sessionLinks.public.map((item:any, index:any)=>{
                return (<li><a key={index} href={item.url} className={`${item.class}`}>{item.label}</a></li>)
              })}
            </ul>
          </div>
        )}
      </Dropdown>
      }</div>
    </header>)
  }
  else {
    return (
      <header className='header-desktop' style={style}>
        <div className="wrapper-content">
          <div><a href={(session.account.account_id!==null) ? sessionLinks.logo.url : './'}><div className='logo'></div></a></div>
        </div>
        {(session.account.account_id!==null) ? (
          <div className="wrapper-session">
            <div className="wrapper-nav">{children}</div>
            <div className="item">
              <button className="btn-transparent btn-user" onClick={handleToggle}>
                <div className="label">{session.account.account_firstname}</div> <Icon.IconUser />
              </button>
            </div>
            {toggleDropdown && <Dropdown closeToggle={closeToggle}>
              <div  className="header-menu-session hidden">
                {sessionLinks.private.map((item:any, index:any)=>{
                  return (<div className="item"><a key={index} href={item.url} className={`${item.class}`}>{item.label}</a></div>)
                })}
              </div>
            </Dropdown>}
          </div>
        ) : (
          <div className="wrapper-session no-margin-right">
            <div className="wrapper-nav">{children}</div>
            <div className="wrapper-btn-session">
              {sessionLinks.public.map((item:any, index:any)=>{
                return (<a key={index} href={item.url} className={`${item.class}`}>{item.label}</a>)
              })}
            </div>
          </div>   
        )}
        {/*<div className='page403' dangerouslySetInnerHTML={{ __html: " "+url_page+" "}} />*/}
      </header>
    )
  }
}

export const HeaderDashboard = ({ children, sessionLinks }: Props) => {
  const session = JS.getSession();
  const { account } = session;
  const { device } = JS.useWindowDimensions();

  const [toggleDropdown, setToggleDropdown] = useState(false);
  const handleToggle = (e:any) => {
    e.preventDefault();
    setToggleDropdown((prevState) => !prevState);
  };
  const closeToggle = () => {
    setToggleDropdown(false);
  };

  return (
    <header className='header-desktop header-dashboard'>
      <div className="wrapper-content">
      </div>
      {(session.account.account_id!==null) ? (
        <div className="wrapper-session">
          <div className="wrapper-nav">{children}</div>
          <div className="item">
            <button className="btn-transparent btn-user" onClick={handleToggle}>
              <div className="label">{session.account.account_firstname}</div> <Icon.IconUser />
            </button>
          </div>
          {toggleDropdown && <Dropdown closeToggle={closeToggle}>
            <div  className="header-menu-session hidden">
              {sessionLinks.private.map((item:any, index:any)=>{
                return (<div className="item"><a key={index} href={item.url} className={`${item.class}`}>{item.label}</a></div>)
              })}
            </div>
          </Dropdown>}
        </div>
      ) : (
        <div className="wrapper-session no-margin-right">
          <div className="wrapper-nav">{children}</div>
          <div className="wrapper-btn-session">
            {sessionLinks.public.map((item:any, index:any)=>{
              return (<a key={index} href={item.url} className={`${item.class}`}>{item.label}</a>)
            })}
          </div>
        </div>   
      )}
      {/*<div className='page403' dangerouslySetInnerHTML={{ __html: " "+url_page+" "}} />*/}
    </header>)
/*
  return (
    <header>
    <div className={`header-dashboard ${device}`}>
      <div>
      </div>
      <div>
        <div className='header-nav'>
          <div className='header-items'>
            {children}
            <div className='header-item'>
              <button className="btn-transparent btn-user" onClick={handleToggle}>
                <div className="label">{`${account.firstname} ${account.lastname}`}</div> <Icon.IconUser />
              </button>
            </div>
            {toggleDropdown && <Dropdown closeToggle={closeToggle}>
              <div  className="header-menu-session hidden">
                {sessionLinks.private.map((item:any, index:any)=>{
                  return (<div className="item"><a key={index} href={item.url} className={`${item.class}`}>{item.label}</a></div>)
                })}
              </div>
            </Dropdown>}
          </div>
        </div>
      </div>
    </div>
    </header>
  )*/
}