import * as React from "react"

function IconClose(props: any) {
  const size = (props.size) ? props.size : 'normal';
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`icon ${size}`}
    >
      <circle cx={14} cy={14} r={10} fill="#fff" />
      <path
        d="M18.769 16.526 16.244 14l2.524-2.525a.796.796 0 0 0 0-1.122L17.645 9.23a.797.797 0 0 0-1.122 0l-2.525 2.525-2.524-2.525a.797.797 0 0 0-1.122 0l-1.12 1.122a.796.796 0 0 0 0 1.122L11.755 14 9.23 16.526a.796.796 0 0 0 0 1.121l1.122 1.122a.795.795 0 0 0 1.122 0l2.524-2.524 2.524 2.523a.797.797 0 0 0 1.124 0l1.122-1.12a.796.796 0 0 0 0-1.122Z"
        fill="#000"
      />
    </svg>
  )
}

export default IconClose
