import * as React from "react"

function IconPlayCircle(props: any) {
  const size = (props.size) ? props.size : 'normal';

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`icon ${size}`}
    >
      <path
        d="M31.992 15.479C31.724 7.072 24.928.277 16.522.008 11.948-.136 7.67 1.605 4.507 4.87 1.467 8.009-.131 12.147.008 16.523c.269 8.406 7.064 15.2 15.47 15.47a15.872 15.872 0 0012.016-4.861c3.04-3.14 4.638-7.278 4.498-11.653zm-6.919 9.308a12.53 12.53 0 01-9.488 3.838c-6.634-.212-11.997-5.575-12.209-12.21a12.54 12.54 0 013.55-9.2 12.53 12.53 0 019.489-3.838c6.634.212 11.997 5.574 12.21 12.209a12.541 12.541 0 01-3.552 9.2z"
        fill="#fff"
      />
      <path
        d="M21.248 15.07l-7.095-5.087a1.144 1.144 0 00-1.81.93v10.175a1.144 1.144 0 001.81.93l7.095-5.088a1.144 1.144 0 000-1.86z"
        fill="#fff"
      />
    </svg>
  )
}

export default IconPlayCircle