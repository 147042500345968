import React from 'react'
import validateInput from './validate';
// import styles from './styles.module.css'

interface Props {
  type?: string | 'text';
  name: string;
  placeholder?: string;
  value?: string;
  onChange?: any;
  className?: string;
}

export const Input = ({ type, name, value, placeholder, onChange, className }: Props) => {
  const validation = validateInput({type, value});

  return (
    <div>
      <input type={type} className={`inp ${className}`} name={name} value={value} placeholder={placeholder} onChange={onChange} />
      {((validation) && (value && value.length>0)) ? <div className='inp-message error'>{validation.message}</div> : null}
    </div>
  )
}

export const InputRadio = ({ id, value, label, onChange }: any) => {

  return (
    <div className='inp-radio'>
      <input type="radio" id={id} value={value}  onChange={onChange}/>
      <label>{label}</label>
    </div>
  )
}

export const InputCheckbox = ({ id, value, label, onChange }: any) => {

  return (
    <div className='inp-checkbox'>
      <input type="checkbox" id={id} value={value}  onChange={onChange}/>
      <label>{label}</label>
    </div>
  )
}