import * as React from "react"

function IconClose(props: any) {
  const size = (props.size) ? props.size : 'normal';
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`icon ${size}`}
    >
      <path
        d="M14.09 19.59 15.5 21l5-5-5-5-1.41 1.41L16.67 15H7v2h9.67l-2.58 2.59ZM23 7H9a2 2 0 0 0-2 2v4h2V9h14v14H9v-4H7v4a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2Z"
        fill="#fff"
      />
    </svg>
  )
}

export default IconClose
