interface IValidate {
  type?: string,
  value?: string
}

function validateText(text?: string) {
  if(text && (text.length>3)) {
    return true;
  }
  return false;
}

function validateEmail(email?: string) {
  const regular_expression = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if(email!==undefined && regular_expression.test(email)) {
    return true;
  }
  return false;
}

function validatePassword(password?: string) {
  if(password && (password.length>3)) {
    return true;
  }
  return false;
}

export default function validateInput({type, value}:IValidate) {
  switch(type) {
    case 'text':
      return (validateText(value) ? {status: true, message: ''} : {status: false, message: 'Debe contener al menos 3 caracteres'});
    case 'email':
      return (validateEmail(value) ? {status: true, message: ''} : {status: false, message: 'Email incorrecto'});
    case 'password':
      return (validatePassword(value) ? {status: true, message: ''} : {status: false, message: 'Password incorrecto'})
    default:
      return false;
  }
}
