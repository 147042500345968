import React from 'react'
// import style from './Containers.css';

interface Props {
  children?: any;
  maxWidth?: string;
  className?: string;
  bgImage?: string;
}

export const Container = ({ children, className }: Props) => {
  const styleClassName = className ? className : '';
  return <div className={`container ${styleClassName}`}>{children}</div>
}

export const Section = ({ children, maxWidth, className, bgImage }: Props) => {
  const styleClassName = className ? className : '';
  const maximunWidth = (maxWidth: string | undefined) => {
    switch (maxWidth) {
      case 'small':
        return '480px';
        break;
      case 'medium':
        return '900px';
        break;			
      case 'large':
        return '1200px';
        break;
      default:
        return '100%';    
    }
  }

  const styleUpdate = {
    content: {
      maxWidth: maximunWidth(maxWidth)
    },
    bgImage: {
      backgroundImage: `url("${bgImage}")`
    }
  };
  return (
    <div className={`section ${styleClassName}`} style={(bgImage) ? styleUpdate.bgImage : undefined}>
      <div style={styleUpdate.content} className='sectionContent'>{children}</div>
    </div>
  );
}

export const Row = ({ children, className }: Props) => {
  const styleClassName = className ? className : '';
  return <div className={`row ${styleClassName}`}>{children}</div>
}

export const Col = ({ children, className }: Props) => {
  const styleClassName = className ? className : '';
  return <div className={`col ${styleClassName}`}>{children}</div>
}

export const LineDivisor = ({ className }: Props) => {
  const styleClassName = className ? className : '';
  return <div className={`line-divisor ${styleClassName}`}></div>
}

export const Box = ({ children, className }: Props) => {
  return <div className={`box ${className}`}>{children}</div>
}