
import React from "react";
// import { ReactComponent as Logo } from "./IconLogo.svg";
import Logo from "./IconLogo";
import FooterLogo from "./IconFooterLogo";
import Instagram from "./IconInstagram";
import Facebook from "./IconFacebook";
import Linkedin from "./IconLinkedin";
import Youtube from "./IconYoutube";
import TikTok from "./IconTikTok";

import PlayCircle from "./IconPlayCircle";
import Info from "./IconInfo";
import Filter from "./IconFilter";
import User from "./IconUser";
import Exit from "./IconExit";
import Home from "./IconHome";

import Close from "./IconClose";
import Menu from "./IconMenu";

interface Props {
  size?: string;
}

/* LOGO */
const IconLogo = ({size}: Props) => <Logo size={size} />;
const IconFooterLogo = ({size}: Props) => <FooterLogo size={size}/>;

/* SOCIAL ICONS*/
const IconInstagram = ({size}: Props) => <Instagram size={size}/>;
const IconLinkedin = ({size}: Props) => <Linkedin size={size}/>;
const IconFacebook = ({size}: Props) => <Facebook size={size}/>;
const IconYoutube = ({size}: Props) => <Youtube size={size}/>;
const IconTikTok = ({size}: Props) => <TikTok size={size}/>;

/* BUTTON ICONS */
const IconPlayCircle = ({size}: Props) => <PlayCircle size={size}/>;
const IconInfo = ({size}: Props) => <Info size={size}/>;
const IconClose = ({size}: Props) => <Close size={size}/>;
const IconFilter = ({size}: Props) => <Filter size={size}/>;
const IconUser = ({size}: Props) => <User size={size}/>;
const IconExit = ({size}: Props) => <Exit size={size}/>;
const IconHome = ({size}: Props) => <Home size={size}/>;

const IconMenu = ({size}: Props) => <Menu size={size}/>;

export {
  IconLogo,
  IconFooterLogo,
  IconInstagram,
  IconLinkedin,
  IconFacebook,
  IconYoutube,
  IconTikTok,
  IconPlayCircle,
  IconInfo,
  IconClose,
  IconFilter,
  IconUser,
  IconExit,
  IconHome,
  IconMenu
}
