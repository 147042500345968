import React from 'react'

interface Props {
  children?: any;
  className?: string;
}

export const TagCategory = ({ children, className }: Props) => {
  return <div className={`tag-category ${className}`}>{children}</div>
}
