import * as React from "react"

function IconFacebook(props: any) {
  const size = (props.size) ? props.size : 'normal';

  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`icon ${size}`}
    >
      <g clipPath="url(#a)">
        <path
          d="M16 0a16 16 0 1 0 0 32 16 16 0 0 0 0-32Zm4.552 10.413c0 .3-.127.42-.421.42-.567 0-1.133 0-1.697.024-.565.023-.872.28-.872.87-.013.63 0 1.25 0 1.894h2.423c.346 0 .463.118.463.465v2.54c0 .342-.109.45-.456.452h-2.45v6.85c0 .367-.115.482-.477.482H14.43c-.317 0-.442-.124-.442-.442v-6.88h-2.092c-.329 0-.447-.12-.447-.45v-2.554c0-.328.125-.452.449-.452h2.09V11.79a4.609 4.609 0 0 1 .575-2.371 3.476 3.476 0 0 1 1.88-1.562 4.613 4.613 0 0 1 1.61-.278h2.07c.297 0 .421.13.421.421.01.81.01 1.613.009 2.413Z"
          fill="#607D8B"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconFacebook
