import * as React from "react"

function IconYoutube(props: any) {
  const size = (props.size) ? props.size : 'normal';

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`icon ${size}`}
    >
      <g clipPath="url(#a)" fill="#607D8B">
        <path d="M13.739 12.636v6.82l2.759-1.707 2.759-1.706-2.76-1.7-2.758-1.707Z" />
        <path d="M16 0C7.16 0 0 7.16 0 16s7.16 16 16 16 16-7.16 16-16S24.84 0 16 0Zm8.889 18.965a3.235 3.235 0 0 1-3.229 3.229H10.34a3.235 3.235 0 0 1-3.229-3.229v-5.923a3.235 3.235 0 0 1 3.229-3.229h11.32a3.235 3.235 0 0 1 3.229 3.229v5.923Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconYoutube
