import * as React from "react"

function IconLinkedin(props: any) {
  const size = (props.size) ? props.size : 'normal';

  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`icon ${size}`}
    >
      <g clipPath="url(#a)">
        <path
          d="M16 0a16 16 0 1 0 0 32 16 16 0 0 0 0-32Zm-4.653 24.421h-3.49V13.177h3.49V24.42ZM9.6 11.642a2.03 2.03 0 1 1-.017-4.062 2.03 2.03 0 0 1 .017 4.062Zm14.821 12.78h-3.488v-5.475c0-1.305-.026-2.979-1.815-2.979-1.79 0-2.105 1.42-2.105 2.885v5.568h-3.476V13.177h3.35v1.533h.048c.465-.885 1.604-1.817 3.303-1.817 3.532 0 4.183 2.328 4.183 5.351v6.177Z"
          fill="#607D8B"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconLinkedin
