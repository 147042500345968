import React from "react";
// Import Swiper React components
// import required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";

// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/scrollbar/scrollbar.min.css'

import '../../css/scrolls.css'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

interface Props {
  component: any;
  data: Array<any>;
  scale: number;
}

export const ScrollHorizontal = ({ component, data, scale } : Props) => {
  const scale_number = scale ? scale : 0;
  // console.log(scale_number);
  // console.log(component);
  // console.log(data);
  return (
    <div className="scroll-horizontal-wrapper">
      <Swiper

        /*spaceBetween={10}
        slidesPerView={parseFloat(`${scale_number}.5`)}
        navigation
        /*pagination={{ clickable: true }}
        /*scrollbar={{ draggable: true }}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}*/
        spaceBetween={10}
        slidesPerView={parseFloat(`${scale_number}.5`)}
        navigation
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
      {data.map((item: any, index: any) => {
          return (
            <SwiperSlide key={index}>
              <div key={item.id}>
                {React.cloneElement(component, { data: item })}
              </div>
            </SwiperSlide>
          );
        })}
        {/*<SwiperSlide className="slide"><div className="slide-content">Slide 1</div></SwiperSlide>
        <SwiperSlide className="slide"><div className="slide-content">Slide 2</div></SwiperSlide>
        <SwiperSlide className="slide"><div className="slide-content">Slide 3</div></SwiperSlide>
        <SwiperSlide className="slide"><div className="slide-content">Slide 4</div></SwiperSlide>
        <SwiperSlide className="slide"><div className="slide-content">Slide 5</div></SwiperSlide>
        <SwiperSlide className="slide"><div className="slide-content">Slide 6</div></SwiperSlide>
        <SwiperSlide className="slide"><div className="slide-content">Slide 7</div></SwiperSlide>
        <SwiperSlide className="slide"><div className="slide-content">Slide 8</div></SwiperSlide>
        <SwiperSlide className="slide"><div className="slide-content">Slide 9</div></SwiperSlide>
      <SwiperSlide className="slide"><div className="slide-content">Slide 10</div></SwiperSlide>*/}
      </Swiper>
    </div>
  );
}
