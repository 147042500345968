import * as React from "react"

function IconTikTok(props: any) {
  const size = (props.size) ? props.size : 'normal';

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`icon ${size}`}
    >
      <g clipPath="url(#a)">
        <path
          d="M16 0C7.165 0 0 7.165 0 16s7.165 16 16 16 16-7.165 16-16S24.835 0 16 0Zm8.027 12.242v2.166a7.542 7.542 0 0 1-4.626-1.572l.015 6.669a5.586 5.586 0 0 1-1.675 3.975 5.702 5.702 0 0 1-3.186 1.593c-.282.042-.57.063-.86.063a5.721 5.721 0 0 1-4.046-1.656 5.59 5.59 0 0 1-1.665-4.355 5.597 5.597 0 0 1 1.412-3.376 5.721 5.721 0 0 1 4.299-1.923c.29 0 .578.022.86.064V16.92a2.703 2.703 0 0 0-3.557 2.603 2.7 2.7 0 0 0 1.32 2.275 2.69 2.69 0 0 0 2.237.246 2.7 2.7 0 0 0 1.855-2.562l.004-4.466V6.864h2.983a4.615 4.615 0 0 0 1.839 3.645c.774.584 1.738.93 2.782.93h.009v.803Z"
          fill="#607D8B"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconTikTok
