import React from 'react'
import * as Icon from '../icons/Icons';

interface Props {
  component?: any;
  className?: string;
  close?: any;
}

export const Modal = ({ component, close, className }: Props) => {
  if(!component) return null;

  return (
    <div className={`modal-wrapper ${className}`}>   
      <div className="modal-box">
        <div className="header"><button className="btn-transparent" onClick={()=>close()}><Icon.IconClose /></button></div>
        <div className="content">{component}</div>
      </div>
    </div>
  )
}